import React from "react";
import "./privacyPolicy.css";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import laptop from "./laptop.png";
export default function PrivacyPolicy() {
  return (
    <div className="pt-2 pt-md-4 bg-secondary">
      <Breadcrumb page="Privacy Policy" image={laptop} />
      <div className="container bg-white py-4 px-5 box-shadow privacyContent">
        <h4 className="poppins fw-500 text-primary">Privacy Policy</h4>
        <p>
          2nd Gear, Inc. has created this privacy statement in order to
          demonstrate our firm commitment to privacy. The following discloses
          our information gathering and dissemination practices for our website:{" "}
          <a href="www.2gear.in"> www.2gear.in</a>{" "}
        </p>
        <h5>Personal information you provide us</h5>
        <p>
          At registration, the user is required to give us contact information
          such as name, address, email address, telephone number and interests.
          The user might also provide the information at exhibition shows
          (events), or any of the web forms in our page, or in person when in
          conversation with a representative of 2nd Gear. We keep records of the
          correspondence and transactional information.
        </p>
        <h5>Information we collect through our website (including cookies)</h5>
        <p>
          Our website uses cookies. These text files are placed on your computer
          to collect Standard Internet Log information and visitor information,
          this allow us to differentiate users of the website from each other
          collect information about their visit to our website.
        </p>
        <h5>Personal information provided by third parties</h5>
        <p>
          We may receive personal information from third parties, which may
          include parties who provide this information in fulfillment of a legal
          obligation. We may obtain information about you from publicly
          available records.
        </p>
        <h5>How we use personal information</h5>
        <p>
          1. The basis for the processing of the information is to perform the
          contract with you related to the service we provide or because the
          user asked 2nd Gear to take specific steps before entering into a
          contract in respect to these activities and services.{" "}
        </p>
        <p>
          2. To administer our Website, our events and for internal operations,
          including troubleshooting, data analysis, testing, statistical and
          survey purposes; to improve our Website. This also allow us to perform
          trend monitoring, marketing and advertising.{" "}
        </p>
        <p>3. To identify our users and administrate their accounts. </p>
        <p>
          4. To respond to any communications that a user may send us. This
          processing is necessary for the administration of our business and the
          provision of our services, which is necessary for the legitimate
          interests of our business.{" "}
        </p>
        <h5>How we use personal information</h5>
        <p>
          We may receive personal information from third parties, which may
          include parties who provide this information in fulfillment of a legal
          obligation. We may obtain information about you from publicly
          available records.
        </p>
        <h5>How do we share and disclose information to third parties?</h5>
        <div>
          <p>
            1. Third party vendors who we employ to perform tasks on our behalf.
            These companies include (for example) our payment processing
            providers, website analytics companies (e.g., Google Analytics), CRM
            service providers, email service providers and others.
          </p>
          <p>
            2. Third party vendors that provide professional services to us,
            e.g. advertising partners, web hosting providers, etc.
          </p>
          <p>
            3. In a business transfer we may choose to buy or sell assets, and
            may share and/or transfer customer information in connection with
            the evaluation of and entry into such transactions. Also, if we (or
            our assets) are acquired, or if we go out of business, enter
            bankruptcy, or go through some other change of control, Personal
            Information could be one of the assets transferred to or acquired by
            a third party.
          </p>
          <p>
            4.Under certain circumstances, we may be required to disclose your
            Personal Information in response to valid requests by public
            authorities, including to meet national security or law enforcement
            requirements.
          </p>
        </div>
        <h5>International Data Transfers</h5>
        <p>
          Personal Information you submit on the Websites or through the
          Services may be sent to the United States and processed by us there or
          in other countries, on our service providers’ cloud servers.
        </p>
        <h5>How to contact 2nd Gear</h5>
        <p>
          The data controller responsible for your personal information for the
          purposes of data protection legislation is: 2nd Gear, Inc.ATTN: Data
          Controller 5 Dan Road Canton, MA 02021 Phone: 781.821.3482
        </p>
        <h5>How to contact 2nd Gear</h5>
        <p>
          The data controller responsible for your personal information for the
          purposes of data protection legislation is: 2nd Gear, Inc.ATTN: Data
          Controller 5 Dan Road Canton, MA 02021 Phone: 781.821.3482
          Email: privacy@2nd Gear.com If you have any questions about this
          Privacy Notice or our data collection practices, please contact us at
          the address, telephone number or email listed above and specify your
          country of residence and the nature of your question. We will offer an
          answer to your request in 7 to 20 days from the date the request is
          sent, this complying with the laws in each country.
        </p>
        <h5>Retention of your data</h5>
        <p>
          We will not retain your personal data for longer than is necessary in
          relation to the purposes for which the data was collected or otherwise
          processed.
        </p>

        <h5>Your Privacy Rights</h5>
        <h5>What choices do I have?</h5>
        <p>
          You can always opt not to disclose information to us, but keep in mind
          some information may be needed to register with us or to take
          advantage of some of our features.
        </p>
        <h5>How Can I Exercise My Data Subject Rights?</h5>
        <p>
          If you would like to access, review, update, rectify, and delete any
          Personal Information we hold about you, or exercise any other data
          subject right available to you under the EU General Data Protection
          Regulation (GDPR), you can write us at privacy@2nd Gear.com with your
          request. Our privacy team will examine your request and respond to you
          as quickly as possible. Please note that we may still use any
          aggregated and de-identified Personal Information that does not
          identify any individual, and may also retain and use your information
          as necessary to comply with our legal obligations, resolve disputes,
          and enforce our agreements. <br /> Our site contains links to other
          sites. Please be aware that our privacy policy does not apply to these
          sites. As our business and technology changes, we may occasionally
          update this privacy statement. Changes will be posted to this privacy
          statement web page, and use of information we gather is subject to the
          privacy statement in effect at the time of use. You are responsible
          for periodically reviewing this Privacy Notice. <br />
          If you have any questions about this privacy statement or the
          practices of this site, you can contact us at: 
          <a href="privacy@2nd Gear.com">privacy@2nd Gear.com</a>.
        </p>
      </div>
    </div>
  );
}
