import React from 'react'

export default function UsedEquipment() {
  return (
    <div className='usedEquipment py-3'>
<div className="container">
<h3 className='text-center py-3'>2nd Gear.in deal Used Equipment</h3>
        <p>Surplus deal in different varieties of used equipment like Excavator, Crane, Dumper, Hydra, Truck, Forklift, Backhoe, Loader, batching plant, Transit Mixer, Motor Grader, Crawler, Trailer, Generator, Skidder, body truck Many More..</p>

</div>
        </div>
  )
}
